import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import ExampleCard from './components/ExampleCard';

function handleClick() {
  console.log(handleClick);
  (window.Appcues.track('Navigated to invite a user'));
  (window.analytics.track('Navigated to invite a user'));
}


const ExamplePage = () => (
  <Container className="dashboard">
    <Row>
      <Col>
        <h3 className="page-title">My Account</h3>
      </Col>
      <Col md={2}>
        <Link
          className="btn btn-outline-primary account__btn account__btn--small"
          to="/invite-team"
          onClick={handleClick}
        >
          Invite a team member
        </Link>
      </Col>
    </Row>
    <Row>
      <ExampleCard />
    </Row>
  </Container>
);

export default ExamplePage;
